@import "../../../../global-style.scss";

#user-card-shared-subcomponent {
    cursor: pointer;

    .relative {
        position: relative;
    }

    // Grid
    .search-card-container {
        width: 100%;
        position: relative;
        padding-top: 147%;
        border-radius: 10px;

        .profile-image-container {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            border-radius: inherit;
            z-index: 10;

            .profile-image {
                height: inherit;
                width: 100%;
                object-fit: cover;
                border-radius: inherit;
            }

            .no-profile-image {
                height: 100%;
                width: 100%;
                object-fit: contain;
                border-radius: inherit;
                background-color: #e0e0e0;
            }
        }

        .description-container {
            height: calc(100% - 20px);
            width: calc(100% - 20px);
            position: absolute;
            top: 0;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 10px;
            z-index: 20;
            background: linear-gradient(186.69deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.3) 100%);

            .top-description-container {
                position: relative;
                flex-grow: 1;
                text-decoration: none;

                .live {
                    display: flex;
                    gap: 5px;

                    .live-status {
                        height: 20px;
                        padding: 0 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 3em;
                        font: 800 0.7em Lato;
                        background-color: #ff1694;
                        color: #fff;
                    }

                    .viewer-count-container {
                        height: 20px;
                        min-width: 40px;
                        padding-left: 6px;
                        padding-right: 8px;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        border-radius: 3em;
                        background-color: rgba(196, 196, 196, 0.6);
                        color: white;

                        .eye-logo-container {
                            display: flex;

                            .eye-logo {
                                width: 16px;
                            }
                        }

                        .viewer-count {
                            padding-left: 2px;
                            font: 800 0.8em Lato;
                            color: white;
                        }
                    }
                }

                .private-call-available {
                    width: fit-content;
                    padding: 4px 8px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    border-radius: 50px;
                    font: 600 0.6em Lato;
                    background: linear-gradient(90.18deg, #ff1694 20.88%, #000000 81.7%);
                    color: white;
                }

                .private-call-busy {
                    width: fit-content;
                    padding: 4px 8px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    border-radius: 50px;
                    font: 600 0.6em Lato;
                    background: $sugarbook-main-color;
                    color: white;
                }

                .video-call {
                    width: fit-content;
                    padding: 4px 8px;
                    border-radius: 50px;
                    font: 600 0.6em Lato;
                    background: linear-gradient(90.38deg,
                            #ffa927 5.04%,
                            #ff409c 70.45%,
                            #9534b4 119.21%);
                    color: white;
                }

                .verified-caller-badge-container {
                    display: flex;
                    align-items: center;

                    .verified-caller-badge {
                        height: 13px;
                    }
                }

                .top-right-profile-badge-container {
                    position: absolute;
                    top: 0;
                    right: 0;

                    .lgbtq-icon {
                        height: 25px;
                    }
                }
            }

            .bottom-description-container {
                display: flex;

                .left-detail-container {
                    display: flex;
                    flex-direction: column;
                    gap: 3px;
                    // flex-grow: 1;
                    width: calc(100% - 30px);

                    .within-distance-container {
                        display: flex;
                        gap: 3px;
                        color: white;

                        .pin-icon-container {
                            display: flex;
                            align-items: center;

                            .pin-icon {
                                font-size: 12px;
                            }
                        }

                        .distance-in-km-label {
                            font: italic 500 0.8em Lato;
                            letter-spacing: 1px;
                        }
                    }

                    .badges-container {
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        .livestreaming-achievements-badge-container {
                            display: flex;
                            gap: 3px;

                            .livestreaming-achievements-badge {
                                height: 13px;
                            }
                        }

                        .special-badge-container {
                            display: flex;
                            gap: 3px;

                            .special-badge {
                                height: 15px;
                            }
                        }
                    }

                    .username-description {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        font: 600 1.1em Lato;
                        color: white;

                        .username {
                            max-width: 50%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .age {}

                        .status {
                            height: 10px;
                            width: 10px;
                            // margin: auto 0 3px 0;
                            border-radius: 50px;
                            background-color: rgb(28, 189, 32);
                        }

                        .verified-profile-badge-container {
                            display: flex;
                            align-items: center;

                            .verified-profile-badge {
                                height: 13px;
                            }
                        }

                        .premium-mini-badge-container {
                            display: flex;
                            align-items: center;

                            .premium-mini-badge {
                                height: 13px;
                            }
                        }
                    }

                    .location-description {
                        display: flex;
                        font: 400 0.8em Lato;
                        color: white;

                        .location {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .badge-container {
                            margin-left: 5px;
                            margin-bottom: 1px;
                            display: flex;
                            align-items: flex-end;

                            .badge {
                                height: 12px;
                            }
                        }
                    }
                }

                .right-detail-container {
                    display: flex;
                    align-items: flex-end;

                    .favorite-icon-container {
                        margin-bottom: 8px;

                        .favorite-icon {
                            width: 23px;
                        }
                    }
                }
            }
        }
    }

    // List
    .search-list-container {
        padding: 10px 0;
        display: flex;
        gap: 10px;

        .photo-container {
            width: 20%;

            .profile-photo-container {
                position: relative;

                .profile-photo {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 15px;
                }

                .favorite-icon-container {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;

                    .favorite-icon {
                        width: 30px;
                    }
                }
            }
        }

        .details-container {
            width: 80%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .basic-details-container {
                display: flex;

                .details-container {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .name-age-container {
                        display: flex;
                        gap: 10px;

                        .username {
                            font: 500 1.3em lato;
                        }

                        .age {
                            font: 500 1.3em lato;
                        }

                        .verified-profile-badge-container {
                            display: flex;
                            align-items: center;

                            .verified-profile-badge {
                                height: 18px;
                            }
                        }

                        .verified-caller-badge-container {
                            display: flex;
                            align-items: center;

                            .verified-caller-badge {
                                height: 15px;
                            }
                        }

                        .premium-mini-badge-container {
                            display: flex;
                            align-items: center;

                            .premium-mini-badge {
                                height: 13px;
                            }
                        }

                        .within-distance-container {
                            width: 20%;
                            display: flex;
                            align-items: center;
                            gap: 3px;
                            color: rgb(137, 137, 137);

                            .pin-icon-container {
                                display: flex;
                                align-items: center;

                                .pin-icon {
                                    font-size: 12px;
                                }
                            }

                            .distance-in-km-label {
                                font: italic 500 1em Lato;
                            }
                        }
                    }

                    .location {
                        color: rgb(137, 137, 137);
                    }

                    .tagline {
                        font: 500 1em/1.3 Lato;
                    }
                }
            }

            .indept-details-container {
                display: flex;

                .left-container {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .height-container {
                        display: flex;
                        gap: 5px;

                        .height-label {
                            font: 600 1em Lato;
                        }

                        .height-value {}
                    }

                    .body-weight-container {
                        display: flex;
                        gap: 5px;

                        .body-weight-label {
                            font: 600 1em Lato;
                        }

                        .body-weight-value {}
                    }
                }

                .right-container {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .ethnicity-container {
                        display: flex;
                        gap: 5px;

                        .ethnicity-label {
                            font: 600 1em Lato;
                        }

                        .ethnicity-value {}
                    }

                    .lifestyle-container {
                        display: flex;
                        gap: 5px;

                        .lifestyle-label {
                            font: 600 1em Lato;
                        }

                        .lifestyle-value {}
                    }
                }

                // Utility CSS
                .full-width {
                    width: 100%;
                }
            }

            .tor-details-container {}
        }

        // Utility CSS
        .larger {
            width: 30%;
        }

        .smaller {
            width: 70%;
        }
    }

    .live-card-video-player-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 10;
        opacity: 0;
        transition: all 0.5s ease-in-out;

        #live-card-video-player {
            height: 100%;
            width: 100%;
            position: relative;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    // Utility CSS
    .private-border-gradient {
        padding: 5px;
        border-radius: 15px;
        background: linear-gradient(90deg, #ffa927, #ff409c 70%, #9534b4) padding-box, linear-gradient(90deg, #ffa927, #ff409c 70%, #9534b4) border-box;
        background-size: 600% 600%;
        animation: luminousGradientPosition 1s ease infinite;
        -webkit-animation: luminousGradientPosition 1s ease infinite;
    }

    .border-gradient {
        padding: 5px;
        border-radius: 15px;
        background-size: 600% 600% !important;
        animation: luminousGradientPosition 1s ease infinite;
        -webkit-animation: luminousGradientPosition 1s ease infinite;
    }

    .column {
        flex-direction: column;
        gap: 10px;
    }

    .custom-card-border-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 20;
        pointer-events: none;

        .custom-card-border {
            height: 100%;
            width: 100%;
        }
    }
}

#lgbtq-tooltip-container {
    padding: 3px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .lgbtq-title-container {
        display: flex;
        align-items: center;
        gap: 5px;

        .lgbtq-badge-container {
            display: flex;

            .lgbtq-badge {
                height: 20px;
            }
        }

        .lgbtq-title {
            font: bold 1.2em Lato;
            color: black;
        }
    }

    .lgbtq-description-container {
        color: #7C7C7C;
    }
}

// Responsive Design
@media (min-width: 720px) {
    #user-card-shared-subcomponent {

        .search-list-container {
            padding: 10px;
        }
    }
}

@keyframes video-player-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}