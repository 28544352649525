#navbar-quick-action-subcomponent {
    cursor: pointer;

    .upgrade-now-button {
        height: 23px;
        padding: 0 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        border: 2px solid #EAB923;
        border-radius: 100px;
        text-overflow: ellipsis;
        font: 800 0.7em Lato;
        background: #F4BF25;
        color: white;

        .premium-star-icon {
            height: 13px;
        }
    }

    .verified-now-button {
        height: 23px;
        padding: 0 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        border: 2px solid #1DA1F3;
        border-radius: 100px;
        text-overflow: ellipsis;
        font: 800 0.7em Lato;
        background: #DCF2FF;
        color: #1DA1F3;

        .verified-profile-icon {
            height: 13px;
        }
    }

    .desktop-verified-now-button {
        height: 34px;
        font: bold 0.8em Lato;

        .verified-profile-icon {
            height: 16px;
        }
    }

    .desktop-upgrade-now-button {
        height: 34px;
        font: bold 0.8em Lato;

        .premium-star-icon {
            height: 13px;
        }
    }
}