#lgbtq-description-dialog {
    max-width: 300px;

    .dialog-header {
        padding: 10px 20px 0 10px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 20px;
            }
        }
    }

    .padding-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0 30px 30px 30px;

        .lgbtq-title-container {
            display: flex;
            align-items: center;
            gap: 15px;

            .lgbtq-title {
                font: bold 1.3em Lato;
            }

            .lgbtq-badge-container {

                .lgbtq-badge {
                    height: 40px;
                }
            }
        }

        .lgbtq-description {
            font: 500 0.9em/1.4 Lato;
        }
    }
}