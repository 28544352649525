#sugarbook-pin-element-subcomponent {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .sugarbook-pin-element-container {
        margin-left: 15px;
        padding: 10px 0 10px 15px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        background: rgba(113, 13, 13, 0.8);

        .sugarbook-pin-username {
            font: 500 0.8em Lato;
            color: white;
        }

        .sugarbook-pin-message {
            font-size: 0.8em;
            line-height: 1.4;
            color: white;
        }
    }
}