#user-profile-top-user-container-subcomponent {
    display: flex;

    .profile-image-container {
        width: 300px;

        .profile-image {
            width: 250px;
            height: 250px;
        }
    }

    .user-info-container {
        width: calc(100% - 300px);
        display: flex;
        flex-direction: column;
        gap: 10px;

        .username-badge-container {
            display: flex;
            align-items: center;
            gap: 5px;

            .status {
                height: 10px;
                width: 10px;
                margin-left: 5px;
                border-radius: 25px;
                background-color: #1cbd20;
            }

            .livestreaming-achievement-badge {
                height: 20px;
            }

            .username {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font: 300 2.1em Lato;
            }

            .action-button-container {
                display: flex;

                .message-button-container {}

                .favorite-button-container {}

                .video-call-button-container {}
            }
        }

        .age-role-country-container {
            display: flex;
            align-items: center;
            gap: 5px;

            .age {
                font: 600 1em Lato;
            }

            .role {
                font: 600 1em Lato;
            }

            .country {
                font: 600 1em Lato;
            }

            .badge-container {
                display: flex;

                .badge {
                    height: 18px;
                }
            }

            .badges-container {
                display: flex;
                align-items: center;
                gap: 10px;

                .verified-profile-badge-container {
                    display: flex;

                    .verified-profile-badge {
                        height: 18px;
                    }
                }

                .verified-caller-badge-container {
                    display: flex;

                    .verified-caller-badge {
                        height: 18px;
                    }
                }

                .membership-badge-container {
                    display: flex;

                    .membership-badge {
                        height: 18px;
                    }
                }

                .special-badges-container {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .special-badges {
                        height: 18px;
                        cursor: pointer;
                    }
                }

                .lgbtq-icon-container {

                    .lgbtq-icon {
                        height: 23px;
                        cursor: pointer;
                    }
                }
            }

            .small-circle {
                height: 5px;
                width: 5px;
                border-radius: 25px;
                background-color: black;
            }
        }

        .tor-details-container {}

        .member-active-container {
            width: 70%;
            padding-top: 10px;
            display: flex;
            // gap: 30px;

            .last-active-container {
                flex: 1;

                .last-active-label {
                    font: 300 1em Lato;
                    color: #333333;
                }

                .last-active {}
            }

            .member-since-container {
                flex: 1;

                .member-since-label {
                    font: 300 1em Lato;
                    color: #333333;
                }

                .member-since {}
            }

            .location-container {
                flex: 1;

                .location-label {
                    font: 300 1em Lato;
                    color: #333333;
                }

                .location {}
            }
        }
    }
}

#lgbtq-tooltip-container {
    padding: 3px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .lgbtq-title-container {
        display: flex;
        align-items: center;
        gap: 5px;

        .lgbtq-badge-container {
            display: flex;

            .lgbtq-badge {
                height: 20px;
            }
        }

        .lgbtq-title {
            font: bold 1.2em Lato;
            color: black;
        }
    }

    .lgbtq-description-container {
        color: #7C7C7C;
    }
}