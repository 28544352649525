@import "../../../global-style.scss";

#video-call-rate-page {

    .max-width-container {
        max-width: 600px;
        margin: 0 auto;
        background-color: white;
    }

    .padding-container {
        padding: 20px;

        .rating-container {
            padding: 30px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 30px;

            .rate-label {
                font: 400 1em Lato;
            }
        }

        .write-review-container {
            display: flex;
            flex-direction: column;
            gap: 35px;

            .review-label {
                font: 700 1.4em Lato;
            }

            .review-input-container {
                width: 100%;

                .review-input {
                    width: 100%;
                }
            }

            .send-button {
                height: 45px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }
        }
    }
}

// Responsive Design
@media (min-width: 600px) {
    #video-call-rate-page {

        .max-width-container {
            border-left: 1px solid #e5e7eb;
            border-right: 1px solid #e5e7eb;
        }
    }
}