@import "../../../../global-style.scss";

#search-search-filter-subcomponent {
    width: 300px;

    .apply-filter-button {
        height: 45px;
        width: calc(100% - 30px);
        margin: 25px 15px;
        // bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        cursor: pointer;
        text-transform: none;
        text-decoration: none;
        font: 600 1.2em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }

    .reset-filter-button {
        height: calc(45px - 4px);
        width: calc(100% - 30px - 4px);
        margin: 25px 15px;
        bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $sugarbook-main-color;
        border-radius: 25px;
        cursor: pointer;
        text-transform: none;
        text-decoration: none;
        font: 600 1.2em Lato;
        background-color: white;
        color: $sugarbook-main-color;
    }
}