#profile-about-me-subcomponent {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .about-me-label {
            font: 600 1.5em Lato;
        }

        .about-me-container {
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            border-radius: 20px;
            background: white;

            .about-me-item-container {
                padding: 8px 15px;
                display: flex;
                align-items: center;
                gap: 7px;
                border: 1px solid #d8d4d4;
                border-radius: 50px;
                cursor: pointer;
                font: 600 1em Lato;
                background: #f0ecec;

                .lifestyle-budget-icon,
                .height-icon,
                .body-type-icon,
                .ethnicity-icon,
                .eye-color-icon,
                .hair-color-icon,
                .education-icon,
                .relationship-icon,
                .children-icon,
                .smoking-icon,
                .drinking-icon {
                    height: 20px;
                }
            }

            // Utility CSS
            .non-clickable {
                cursor: default;
            }
        }

        .about-me-text-field-container {
            position: relative;

            .about-me-text {
                min-height: 60px;
                margin: 0 10px;
                padding: 10px;
                border: 1px solid #d8d4d4;
                border-radius: 10px;
                font: 500 0.9em/1.5 Lato;
                color: black;
                background: #f0ecec;
            }

            .blur-filter {
                height: calc(100% - 2px);
                width: calc(100% - 22px);
                position: absolute;
                top: 0;
                margin: 1px 11px;
                border-radius: 10px;
                backdrop-filter: blur(5px);
                pointer-events: none;
            }

            .upgrade-to-read-container {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 7px;
                font: bold 0.9em Lato;

                .upgrade-to-read-padding-container {
                    padding: 6px 17px 6px 12px;
                    display: flex;
                    align-items: center;
                    gap: 7px;
                    border-radius: 50px;
                    cursor: pointer;
                    background: #f8d494;

                    .lock-icon-container {

                        .lock-icon {
                            filter: brightness(0) saturate(100%) invert(50%) sepia(100%) saturate(500%) hue-rotate(10deg);
                        }
                    }

                    .upgrade-to-read-label {}
                }
            }

            .non-premium-container {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;

                .non-premium-title {
                    text-align: center;
                    font: bold 1em Lato;
                }

                .non-premium-description {
                    text-align: center;
                    font: 500 0.8em Lato;
                }
            }
        }
    }
}

@media (max-width: 720px) {
    #profile-about-me-subcomponent {

        .padding-container {

            .about-me-container {

                .about-me-item-container {
                    font: 600 0.9em Lato;
                }
            }
        }
    }
}