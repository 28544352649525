#coins-expiry-banner-subcomponent {
    padding: 12px 0;
    text-align: center;
    font: bold 1em/1.5 lato;
    background: #FF0000;
    color: white;

    .yellow-expiry-text {
        color: #EFFF33;
    }

    .underline {
        text-decoration: underline;
        cursor: pointer;
    }
}