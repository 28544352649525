@import "../../../../../global-style.scss";

#conversation-list-pre-request-video-call-subcomponent {
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .pre-request-video-call-layout {
        border-radius: 25px;
        background-color: white;

        .top-container {
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;

            .profile-photos-container {
                display: flex;

                .caller-picture {
                    height: 60px;
                    width: 60px;
                    position: relative;
                    left: 5px;
                    z-index: 2;
                }

                .callee-picture {
                    height: 60px;
                    width: 60px;
                    position: relative;
                    right: 5px;
                    z-index: 1;
                }
            }

            .username-calling-label {
                text-align: center;
                font: 600 1.4em Lato;
            }

            .earn-amount-label {

                .first-row {
                    display: flex;
                    justify-content: center;
                    gap: 5px;
                    text-align: center;

                    .text {
                        font: 600 1.2em Lato;
                    }

                    .diamond-icon-container {
                        display: flex;
                        align-items: center;

                        .diamond-icon {
                            height: 13px;
                        }
                    }

                    .diamond-amount-text {
                        font: 900 1.2em Lato;
                        color: #4DC5FF;
                    }
                }

                .second-row {
                    text-align: center;
                    font: 800 1.3em Lato;
                    color: #11A904;
                }

                .third-row {
                    font: 600 1.2em Lato;
                }
            }

            .call-request-rate-description {
                text-align: center;
                font: 600 1.2em/1.4 Lato;

                .amount-text {
                    color: #11A904;
                }

                .diamond-text {
                    color: #4DC5FF
                }

                .diamond-icon {
                    height: 13px;
                }

                .coin-text {
                    color: #FFE18C;
                }

                .coin-icon {
                    padding-right: 3px;
                    height: 13px;
                }
            }
        }

        .bottom-container {
            padding: 20px;
            display: flex;
            flex-direction: column;

            .tips-header {
                font: 700 0.9em Lato;
            }

            .tips-unordered-list {
                padding-left: 25px;
                font: 400 0.8em/2 Lato;

                .highlighted-text {
                    text-decoration: underline;
                    font: 600 1em Lato;
                    color: $sugarbook-main-color;
                }

                .text-with-icon {
                    display: flex;
                    align-items: center;
                    gap: 3px;

                    .diamond-icon-container {
                        display: flex;
                        align-items: center;

                        .diamond-icon {
                            height: 10px;
                        }
                    }
                }
            }
        }
    }

    .call-now-button {
        height: 50px;
        width: 100%;
        padding: 15px 0;
        border-radius: 25px;
        text-align: center;
        text-transform: uppercase;
        font: 700 1.1em Lato;
        cursor: pointer;
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
        background: linear-gradient(90.38deg, rgb(80, 179, 67) 5.04%, rgb(20, 130, 5) 119.21%);
        color: white;
    }

    .buttons-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .settings-button {
            width: 100%;
            max-width: 180px;
            padding: 13px 15px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            border: 2px solid $sugarbook-main-color;
            border-radius: 25px;
            cursor: pointer;
            background-color: white;

            .settings-icon-container {
                display: flex;
                align-items: center;

                .settings-icon {
                    height: 20px;
                }
            }

            .settings-label {
                height: 20px;
                text-transform: uppercase;
                font: 600 1em Lato;
                color: $sugarbook-main-color;
            }
        }

        .learn-more-button {
            width: 100%;
            max-width: 180px;
            padding: 13px 15px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            border: 2px solid $sugarbook-main-color;
            border-radius: 25px;
            cursor: pointer;
            background-color: white;

            .learn-more-icon-container {
                display: flex;
                align-items: center;

                .learn-more-icon {
                    height: 20px;
                }
            }

            .learn-more-label {
                height: 20px;
                white-space: nowrap;
                text-transform: uppercase;
                font: 600 1em Lato;
                color: $sugarbook-main-color;
            }
        }
    }
}