.verified-profile-badge-container,
.unverified-profile-badge-container {
    display: flex;
    cursor: pointer;
}

#verified-profile-tooltip-container {
    padding: 3px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .verified-profile-title-container {
        display: flex;
        align-items: center;
        gap: 5px;

        .verified-profile-badge-container {
            display: flex;

            .verified-profile-badge {
                height: 20px;
            }
        }

        .verified-profile-title {
            font: bold 1.2em Lato;
            color: black;
        }
    }

    .verified-profile-description-container {
        color: #7C7C7C;
    }
}