@import "../../../../../global-style.scss";

#profile-view-basic-info-subcomponent {
    display: flex;
    flex-direction: column;

    .padding-container {
        padding: 20px;
    }

    .info-container {
        display: grid;
        gap: 5px;

        .livestreaming-achievement-badge-container {
            display: flex;
            align-items: center;

            .livestreaming-achievement-badge {
                height: 20px;
                padding: 0 5px;
            }
        }

        .status-name-age-container {
            display: flex;
            align-items: center;
            gap: 5px;

            .status-container {
                width: 18px;

                .status {
                    height: 10px;
                    width: 10px;
                    margin-left: 5px;
                    border-radius: 25px;
                    background-color: #1cbd20;
                }
            }

            .name {
                // font: 300 1.8em Lato;
                font: 600 1.5em Lato;
            }

            .age {
                padding-top: 3px;
                font: 600 1.2em Lato;
            }

            .misc-container {
                margin-top: 3px;
                display: flex;
                align-items: center;
                gap: 7px;

                .verified-profile-badge-container {
                    display: flex;

                    .verified-profile-badge {
                        height: 20px;
                    }
                }

                .premium-badge-container {
                    display: flex;

                    .premium-badge {
                        height: 20px;
                    }

                    .star-icon {
                        font-size: 13px;
                        color: white;
                    }

                    .premium-label {
                        font: 600 12px Lato;
                        color: white;
                    }
                }

                .verified-caller-badge-container {
                    display: flex;

                    .verified-caller-badge {
                        height: 20px;
                    }
                }
            }
        }

        .location-container {
            display: flex;
            align-items: center;

            .pin-icon-container {
                display: flex;
                align-items: center;

                .pin-icon {
                    width: 20px;
                    color: lightgrey;
                }
            }

            .location {
                max-width: 50%;
                padding-left: 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                // font: bold 0.9em Lato;
                font: 500 16px Lato;
                color: #676767;
            }

            .badge-container {
                padding-left: 7px;
                display: flex;

                .badge {
                    height: 13px;
                }
            }

            .lgbtq-icon-container {
                padding-left: 7px;

                .lgbtq-icon {
                    height: 23px;
                    cursor: pointer;
                }
            }
        }
    }
}