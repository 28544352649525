#navbar-coin-subcomponent {
    height: 40px;
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .sb-coin-icon {
        width: 27px;
    }

    .sb-coin-small-icon {
        width: 20px;
    }

    .coins-expiring-countdown {
        text-align: center;
        font: bold 0.5em Lato;
        color: black;

        .red-expiring-label {
            color: #FF0000;
        }
    }
}