@import "../../../../global-style.scss";

#payment-method-selection-subcomponent {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .select-your-payment-label {
        font: 600 1em Lato;
    }

    .payment-secure-logo-container {
        display: flex;
        gap: 10px;

        .discrete-badge {}

        .security-badge {}

        .ssl-badge {}
    }

    .payment-method-container {
        min-height: 115px;
        padding: 10px 0;
        display: flex;
        border: 2px solid transparent;
        border-radius: 20px;
        cursor: pointer;
        background-color: #F6F6F6;

        .payment-icon-container {
            width: 90px;
            display: flex;
            justify-content: center;
            align-items: center;

            .lock-icon {
                height: 40px;
            }

            .fpx-icon {
                height: 28px;
            }

            .payment-icon {}
        }

        .payment-description-container {
            width: calc(100% - 90px - 70px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            gap: 5px;

            .payment-title {
                font: 600 1em Lato;
                color: #4A4A4A;
            }

            .payment-description {
                font: 500 0.8em Lato;
                color: #7C7C7C;
            }

            .sub-payment-icons-container {
                display: flex;
                flex-wrap: wrap;
                gap: 4px;

                .sub-payment-icon-container {
                    display: flex;

                    .sub-payment-icon {
                        height: 22px;
                    }
                }
            }
        }

        .select-container {
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;

            .select-payment-container {
                height: 20px;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 3px solid #E5E5E5;
                border-radius: 50px;

                .selected-payment-inner {
                    height: calc(100% - 6px);
                    width: calc(100% - 6px);
                    margin: 3px;
                    border-radius: 50px;
                    background-color: $sugarbook-main-color;
                }
            }
        }
    }

    // Utility CSS
    .selected-payment-provider {
        border: 2px solid $sugarbook-main-color;
    }
}